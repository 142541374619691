import React from 'react'
import axios from 'axios'
import LayoutStore from '../State/LayoutStore'
import LoginModal from '../Pages/LoginPage/LoginModal'
import SessionStore from '../State/SessionStore'
import * as Sentry from '@sentry/react'
import decircular from 'decircular'
import { redirect } from '@tanstack/react-router'
import { usingNewLogin } from '../State/Permissions/HasPermissions'
import { clerk } from '../App'

export async function makeRequest({
    path,
    method,
    data,
    baseURL = process.env.REACT_APP_SERVER_URL,
    maxRetries = 3,
}) {
    //const maxRetries = 3
    const retryDelay = 500 // Delay in milliseconds
    let attempts = 0
    data = decircular(data)
    while (attempts < maxRetries) {
        try {
            const headers = {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            }
            if (usingNewLogin()) {
                const token = await clerk?.session?.getToken?.({
                    template: 'coincraft',
                })
                headers.Authorization = `Bearer ${token}`
            }
            const response = await axios.request({
                baseURL: baseURL,
                url: path,
                method,
                data,
                timeout: 30 * 1000,
                withCredentials: true,
                headers,
            })
            return response
        } catch (error) {
            // Check if the error is a timeout
            if (
                axios.isCancel(error) ||
                error.code === 'ECONNABORTED' ||
                error.message.includes('timeout')
            ) {
                attempts = maxRetries
                throw error // Do not retry on timeouts
            }
            if (error.response?.status === 401) {
                await SessionStore.checkAuthentication()
                attempts = maxRetries
                if (!usingNewLogin()) {
                    LayoutStore.openModal(
                        <LoginModal modalId={'login-modal'} />
                    )
                } else {
                    window.location.replace('/login')
                }
            }
            attempts++
            // check login
            if (attempts >= maxRetries) {
                Sentry.captureException(error)
                // Handle error or throw it after max retries
                if (error.response) {
                    console.error(error.response)
                } else if (error.request) {
                    console.error(error.request)
                } else {
                    console.error('Error', error.message)
                }
                throw error
            }

            // Wait for a specified time before retrying
            await new Promise((resolve) => setTimeout(resolve, retryDelay))
        }
    }
}
