import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import PermissionCollection from '../../State/Collections/PermissionCollection'
import { permissionStructure } from '../../State/Permissions/PermissionStructure'
import {
    PermissionGroup,
    ProjectPermissionGroup,
} from '../StaffPage/StaffPermissionsTab'
import { permissionPresets } from '../../State/Permissions/PermissionPresets'
import { hasPermissionsForAllProjects } from '../../State/Permissions/HasPermissions'
import cuid from 'cuid'
import { StaffMultiselect } from '../../Components/MultiSelect'

export default observer(({ permissionId }) => {
    const permissions = PermissionCollection.permissionsById[permissionId]
    if (!permissions) return null
    return (
        <div className="space-y-10" style={{ width: '58em' }}>
            <div className="text-xl mt-12 mb-12">
                <span className="mr-5 text-lg">Permission Name:</span>
                <input
                    className="input-base"
                    value={permissions.name}
                    onChange={(e) =>
                        permissions.update({ name: e.target.value })
                    }
                />
            </div>
            <div>
                <span className="mr-5 text-lg">Selected Staff:</span>
                <StaffMultiselect
                    selectedStaffMembers={permissions.staff}
                    onChange={(v) => {
                        const previousStaff = [...permissions.staff]
                        v.forEach((staff) => {
                            staff.update({ permissionsId: permissionId })
                        })
                        previousStaff.forEach((staff) => {
                            if (!v.includes(staff)) {
                                staff.update({ permissionsId: null })
                            }
                        })
                    }}
                />
            </div>
            <PermissionOptions
                model={permissions}
                permissions={permissions.settings}
            />
        </div>
    )
})

export const PermissionOptions = ({ permissions, model }) => {
    const handleUpdatePermissions = (key, value) => {
        model.update({
            settings: {
                ...model.settings,
                ...{
                    [key]: value,
                },
            },
        })
        model.staff.forEach((staff) => {
            staff.update({ permissions: model.settings })
        })
    }

    const handleUpdateProjectPermissions = (id, key, value) => {
        const projectPermission = model.settings.projects.find(
            (p) => p.id === id
        )
        if (projectPermission) {
            projectPermission.permissions = {
                ...projectPermission.permissions,
                ...{
                    [key]: value,
                },
            }
        }
        model.update({
            settings: { ...model.settings },
        })
        model.staff.forEach((staff) => {
            staff.update({ permissions: model.settings })
        })
    }

    const handleUpdateFilterPermissions = (id, filter) => {
        const projectPermission = model.settings.projects.find(
            (p) => p.id === id
        )
        if (projectPermission) {
            projectPermission.filter = filter ?? projectPermission.filter
        }
        model.update({
            settings: { ...model.settings },
        })
        model.staff.forEach((staff) => {
            staff.update({ permissions: model.settings })
        })
    }
    const addProjectPermission = () => {
        model.settings.projects.push({
            filter: !hasPermissionsForAllProjects(model.settings)
                ? { type: 'all' }
                : { type: 'project', values: [] },
            id: cuid(),
            permissions: {
                ...permissionPresets.timesheet.projects[0].permissions,
            },
        })
        model.update({
            settings: { ...model.settings },
        })
        model.staff.forEach((staff) => {
            staff.update({ permissions: model.settings })
        })
    }
    return permissionStructure.map((group) =>
        group.label !== 'Projects' ? (
            <PermissionGroup
                title={group.label}
                permissions={group.permissions}
                staffPermissions={permissions || []}
                onChange={handleUpdatePermissions}
            />
        ) : (
            <div>
                {(permissions?.projects || []).map((projectPermissions) => (
                    <ProjectPermissionGroup
                        permissions={group.permissions}
                        staffPermissions={permissions}
                        projectPermissions={projectPermissions}
                        onChange={(key, value) =>
                            handleUpdateProjectPermissions(
                                projectPermissions.id,
                                key,
                                value
                            )
                        }
                        onFilterChange={(value) =>
                            handleUpdateFilterPermissions(
                                projectPermissions.id,
                                value
                            )
                        }
                        onDelete={() =>
                            deleteProjectPermission(projectPermissions.id)
                        }
                    />
                ))}
                <button
                    className="plus-btn border-[#d9d9d9] mb-10"
                    onClick={() => addProjectPermission()}
                >
                    + Add Project Permissions
                </button>
            </div>
        )
    )
}
