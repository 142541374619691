import _ from 'lodash'
import React from 'react'
import { queryFilters } from '../../Queries/queryFilters'
import { canViewPrimaryContact } from '../../State/Permissions/HasPermissions'
import { Link } from '@tanstack/react-router'

export const InvoiceReportColumns = (report) => ({
    description: {
        id: 'description',
        label: 'Invoice #',
        type: 'text',
        width: 15,
        value: (row) => {
            return row.ref
        },
        component: ({ value, stores }) => {
            const { row } = stores
            return (
                <div>
                    <Link to={`/invoices/${row.rowObject.id}`}> {value}</Link>
                </div>
            )
        },
    },
    issuedOn: {
        id: 'issuedOn',
        label: 'Issue Date',
        type: 'date',
        width: 10,
        value: (row) => row.issueDate,
        queryFilters: ({ operator, value }) => {
            return queryFilters.date[operator]('issueDate', value)
        },
        queries: (report) => [
            { collection: 'invoices', fields: ['issueDate'] },
        ],
    },
    dueDate: {
        id: 'dueDate',
        label: 'Due Date',
        type: 'date',
        width: 10,
        value: (row) => row.dueDate,
        queryFilters: ({ operator, value }) => {
            return queryFilters.date[operator]('dueDate', value)
        },
        queries: (report) => [{ collection: 'invoices', fields: ['dueDate'] }],
    },
    startDate: {
        id: 'startDate',
        label: 'Work Start Date',
        type: 'date',
        width: 10,
        value: (row) => row.startDate,
        queryFilters: ({ operator, value }) => {
            return queryFilters.date[operator]('startDate', value)
        },
        queries: (report) => [
            { collection: 'invoices', fields: ['startDate'] },
        ],
    },
    endDate: {
        id: 'endDate',
        label: 'Work End Date',
        type: 'date',
        width: 10,
        value: (row) => row.endDate,
        queryFilters: ({ operator, value }) => {
            return queryFilters.date[operator]('endDate', value)
        },
        queries: (report) => [{ collection: 'invoices', fields: ['endDate'] }],
    },
    createdAt: {
        id: 'createdAt',
        label: 'Creation Date',
        type: 'date',
        width: 10,
        value: (row) => row.createdAt,
        queryFilters: ({ operator, value }) => {
            return queryFilters.date[operator]('createdAt', value)
        },
        queries: (report) => [
            { collection: 'invoices', fields: ['createdAt'] },
        ],
    },
    project: {
        id: 'project',
        label: 'Project',
        type: 'project',
        width: 20,
        value: (row) => row.project,
        queryFilters: ({ operator, value }) => {
            return queryFilters.singleId[operator]('projectId', value)
        },
        queries: (report) => [
            { collection: 'invoices', fields: ['projectId'] },
        ],
    },
    projectTitle: {
        id: 'projectTitle',
        label: 'Project Title',
        type: 'text',
        width: 20,
        visible: false,
        value: (row) => row.project.title,
        queryFilters: ({ operator, value }) => {
            return queryFilters.singleId[operator]('project.name', value)
        },
        queries: (report) => [
            {
                collection: 'invoices',
                fields: ['project.title', 'project.name'],
            },
        ],
    },
    contact: {
        id: 'contact',
        label: 'Client',
        type: 'contact',
        width: 20,
        value: (row) => row.contact,
        queryFilters: ({ operator, value }) => {
            return queryFilters.singleId[operator]('contactId', value)
        },
        queries: (report) => [
            { collection: 'invoices', fields: ['contactId'] },
        ],
    },
    projectContact: {
        id: 'projectContact',
        label: 'Project Client',
        type: 'contact',
        width: 20,
        value: (row) => row.project.contact,
        queryFilters: ({ operator, value }) => {
            return queryFilters.singleId[operator]('projectContactId', value)
        },
        queries: (report) => [
            {
                collection: 'invoices',
                fields: [['projectContactId', 'project.contactId']],
                chain: [
                    {
                        collection: 'projects',
                        fields: ['contactId'],
                        join: { projects: 'id', invoices: 'projectId' },
                    },
                ],
            },
        ],
    },
    projectOwner: {
        id: 'projectOwner',
        label: 'Project Owner',
        type: 'staff',
        width: 20,
        value: (row) => row.project.owner,
        queryFilters: ({ operator, value }) => {
            return queryFilters.singleId[operator]('projectOwnerId', value)
        },
        queries: (report) => [
            {
                collection: 'invoices',
                fields: [['projectOwnerId', 'project.ownerId']],
                chain: [
                    {
                        collection: 'projects',
                        fields: ['ownerId'],
                        join: { projects: 'id', invoices: 'projectId' },
                    },
                ],
            },
        ],
    },
    projectPrimaryContact: {
        id: 'projectPrimaryContact',
        label: 'Project Primary Contact',
        type: 'contact',
        width: 20,
        value: (row) => row.project.primaryContact,
        queryFilters: ({ operator, value }) => {
            return queryFilters.singleId[operator](
                'projectPrimaryContactId',
                value
            )
        },
        queries: (report) => [
            {
                collection: 'invoices',
                fields: [
                    ['projectPrimaryContactId', 'project.primaryContactId'],
                ],
                chain: [
                    {
                        collection: 'projects',
                        fields: ['primaryContactId'],
                        join: { projects: 'id', invoices: 'projectId' },
                    },
                ],
            },
        ],
        permissions: (row) => canViewPrimaryContact(),
    },
    costCentre: {
        id: 'costCentre',
        label: 'Cost Centre',
        type: 'costCentre',
        width: 15,
        value: (row) => row.project.costCentre,
        queryFilters: ({ operator, value }) => {
            return queryFilters.singleId[operator]('projectCostCentreId', value)
        },
        queries: (report) => [
            {
                collection: 'invoices',
                fields: [['projectCostCentreId', 'project.costCentreId']],
                chain: [
                    {
                        collection: 'projects',
                        fields: ['costCentreId'],
                        join: { projects: 'id', invoices: 'projectId' },
                    },
                ],
            },
        ],
    },
    amountExTax: {
        id: 'amountExTax',
        label: 'Amount exc. Tax',
        type: 'currency',
        width: 10,
        value: (row) => row.totalAmount,
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('totalAmount', value)
        },
        queries: (report) => [
            {
                collection: 'invoices',
                fields: [['totalAmount', 'invoiceLineItems.amount']],
                subQueries: [
                    {
                        collection: 'invoiceLineItems',
                        join: 'id == invoiceLineItems.invoiceId',
                        groupBy: ['invoiceId'],
                        fields: [['amount', 'sum(amount)']],
                    },
                ],
            },
        ],
    },
    amountIncTax: {
        id: 'amountIncTax',
        label: 'Amount inc. Tax',
        type: 'currency',
        width: 10,
        value: (row) =>
            InvoiceReportColumns(report).amountExTax.value(row) +
            InvoiceReportColumns(report).amountTax.value(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                'totalAmount + totalTax',
                value
            )
        },
        queries: (report) => [
            ...InvoiceReportColumns(report).amountExTax.queries(report),
            ...InvoiceReportColumns(report).amountTax.queries(report),
        ],
    },
    amountTax: {
        id: 'amountTax',
        label: 'Tax Amount',
        type: 'currency',
        width: 10,
        value: (row) => row.totalTax || 0,
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('totalTax', value)
        },
        queries: (report) => [
            {
                collection: 'invoices',
                fields: [
                    [
                        'totalTax',
                        'invoiceLineItems.taxAmount * (taxRatePercent / 100)',
                    ],
                ],
                subQueries: [
                    {
                        collection: 'invoiceLineItems',
                        join: 'id == invoiceLineItems.invoiceId',
                        groupBy: ['invoiceId'],
                        fields: [['taxAmount', 'sum(isTaxed ? amount : 0)']],
                    },
                ],
            },
        ],
    },
    agreedFeeExTax: {
        id: 'agreedFeeExTax',
        label: 'Agreed Fee Amount exc. Tax',
        type: 'currency',
        width: 10,
        value: (row) => row.agreedAmount,
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('agreedAmount', value)
        },
        queries: (report) => [
            {
                collection: 'invoices',
                fields: [['agreedAmount', 'invoiceLineItems.agreedAmount']],
                subQueries: [
                    {
                        collection: 'invoiceLineItems',
                        join: 'id == invoiceLineItems.invoiceId',
                        groupBy: ['invoiceId'],
                        fields: [
                            [
                                'agreedAmount',
                                'sum(billingType == "agreedFee" ? amount : 0)',
                            ],
                        ],
                    },
                ],
            },
        ],
    },
    variationExTax: {
        id: 'variationExTax',
        label: 'Variation Amount exc. Tax',
        type: 'currency',
        width: 10,
        value: (row) => row.variationAmount,
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('variationAmount', value)
        },
        queries: (report) => [
            {
                collection: 'invoices',
                fields: [
                    ['variationAmount', 'invoiceLineItems.variationAmount'],
                ],
                subQueries: [
                    {
                        collection: 'invoiceLineItems',
                        join: 'id == invoiceLineItems.invoiceId',
                        groupBy: ['invoiceId'],
                        fields: [
                            [
                                'variationAmount',
                                'sum(billingType == "variation" ? amount : 0)',
                            ],
                        ],
                    },
                ],
            },
        ],
    },
    agreedFeeVariationExTax: {
        id: 'agreedFeeVariationExTax',
        label: 'Agreed Fee + Variation Amount exc. Tax',
        type: 'currency',
        width: 10,
        value: (row) =>
            InvoiceReportColumns(report).agreedFeeExTax.value(row) +
            InvoiceReportColumns(report).variationExTax.value(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                'agreedAmount + variationAmount',
                value
            )
        },
        queries: (report) => [
            ...InvoiceReportColumns(report).agreedFeeExTax.queries(report),
            ...InvoiceReportColumns(report).variationExTax.queries(report),
        ],
    },
    reimbursementExTax: {
        id: 'reimbursementExTax',
        label: 'Reimbursement Amount exc. Tax',
        type: 'currency',
        width: 10,
        value: (row) => row.reimbursementAmount,
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('reimbursementAmount', value)
        },
        queries: (report) => [
            {
                collection: 'invoices',
                fields: [
                    [
                        'reimbursementAmount',
                        'invoiceLineItems.reimbursementAmount',
                    ],
                ],
                subQueries: [
                    {
                        collection: 'invoiceLineItems',
                        join: 'id == invoiceLineItems.invoiceId',
                        groupBy: ['invoiceId'],
                        fields: [
                            [
                                'reimbursementAmount',
                                'sum(billingType == "reimbursement" ? amount : 0)',
                            ],
                        ],
                    },
                ],
            },
        ],
    },
    agreedFeeTax: {
        id: 'agreedFeeTax',
        label: 'Agreed Fee Tax',
        type: 'currency',
        width: 10,
        value: (row) => row.agreedTax,
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('agreedTax', value)
        },
        queries: (report) => [
            {
                collection: 'invoices',
                fields: [
                    [
                        'agreedTax',
                        'invoiceLineItems.agreedAmount * (taxRatePercent / 100)',
                    ],
                ],
                subQueries: [
                    {
                        collection: 'invoiceLineItems',
                        join: 'id == invoiceLineItems.invoiceId',
                        groupBy: ['invoiceId'],
                        fields: [
                            [
                                'agreedAmount',
                                'sum(billingType == "agreedFee" ? amount : 0)',
                            ],
                        ],
                    },
                ],
            },
        ],
    },
    variationTax: {
        id: 'variationTax',
        label: 'Variation Tax',
        type: 'currency',
        width: 10,
        value: (row) => row.variationTax,
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('variationTax', value)
        },
        queries: (report) => [
            {
                collection: 'invoices',
                fields: [['variationTax', 'invoiceLineItems.variationAmount']],
                subQueries: [
                    {
                        collection: 'invoiceLineItems',
                        join: 'id == invoiceLineItems.invoiceId',
                        groupBy: ['invoiceId'],
                        fields: [
                            [
                                'variationAmount',
                                'sum(billingType == "variation" ? amount : 0)',
                            ],
                        ],
                    },
                ],
            },
        ],
    },
    agreedFeeVariationTax: {
        id: 'agreedFeeVariationTax',
        label: 'Agreed Fee + Variation Tax',
        type: 'currency',
        width: 10,
        value: (row) =>
            InvoiceReportColumns(report).agreedFeeTax.value(row) +
            InvoiceReportColumns(report).variationTax.value(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                'agreedFeeTax + variationTax',
                value
            )
        },
        queries: (report) => [
            ...InvoiceReportColumns(report).agreedFeeTax.queries(report),
            ...InvoiceReportColumns(report).variationTax.queries(report),
        ],
    },
    reimbursementTax: {
        id: 'reimbursementTax',
        label: 'Reimbursement Tax',
        type: 'currency',
        width: 10,
        value: (row) => row.reimbursementAmount,
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator]('reimbursementAmount', value)
        },
        queries: (report) => [
            {
                collection: 'invoices',
                fields: [
                    [
                        'reimbursementTax',
                        'invoiceLineItems.reimbursementAmount * (taxRatePercent / 100)',
                    ],
                ],
                subQueries: [
                    {
                        collection: 'invoiceLineItems',
                        join: 'id == invoiceLineItems.invoiceId',
                        groupBy: ['invoiceId'],
                        fields: [
                            [
                                'reimbursementAmount',
                                'sum(billingType == "reimbursement" ? amount : 0)',
                            ],
                        ],
                    },
                ],
            },
        ],
    },
    agreedFeeIncTax: {
        id: 'agreedFeeIncTax',
        label: 'Agreed Fee Amount inc. Tax',
        type: 'currency',
        width: 10,
        value: (row) =>
            InvoiceReportColumns(report).agreedFeeExTax.value(row) +
            InvoiceReportColumns(report).agreedFeeTax.value(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                'agreedFeeExTax + agreedFeeTax',
                value
            )
        },
        queries: (report) => [
            ...InvoiceReportColumns(report).agreedFeeExTax.queries(report),
            ...InvoiceReportColumns(report).agreedFeeTax.queries(report),
        ],
    },
    variationIncTax: {
        id: 'variationIncTax',
        label: 'Variation Amount inc. Tax',
        type: 'currency',
        width: 10,
        value: (row) =>
            InvoiceReportColumns(report).variationExTax.value(row) +
            InvoiceReportColumns(report).variationTax.value(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                'variationExTax + variationTax',
                value
            )
        },
        queries: (report) => [
            ...InvoiceReportColumns(report).variationExTax.queries(report),
            ...InvoiceReportColumns(report).variationTax.queries(report),
        ],
    },
    agreedFeeVariationIncTax: {
        id: 'agreedFeeVariationIncTax',
        label: 'Agreed Fee + Variation Amount inc. Tax',
        type: 'currency',
        width: 10,
        value: (row) =>
            InvoiceReportColumns(report).agreedFeeVariationExTax.value(row) +
            InvoiceReportColumns(report).agreedFeeVariationTax.value(row),
        queryFilters: ({ operator, value }) => {
            return queryFilters.number[operator](
                'agreedFeeVariationExTax + agreedFeeVariationTax',
                value
            )
        },
        queries: (report) => [
            ...InvoiceReportColumns(report).agreedFeeVariationExTax.queries(
                report
            ),
            ...InvoiceReportColumns(report).agreedFeeVariationTax.queries(
                report
            ),
        ],
    },
    reimbursementIncTax: {
        id: 'reimbursementIncTax',
        label: 'Reimbursement Amount inc. Tax',
        type: 'currency',
        width: 10,
        agreedFeeVariationIncTax: {
            id: 'agreedFeeVariationIncTax',
            label: 'Agreed Fee + Variation Amount inc. Tax',
            type: 'currency',
            width: 10,
            value: (row) =>
                InvoiceReportColumns(report).reimbursementExTax.value(row) +
                InvoiceReportColumns(report).reimbursementTax.value(row),
            queryFilters: ({ operator, value }) => {
                return queryFilters.number[operator](
                    'reimbursementExTax + reimbursementTax',
                    value
                )
            },
            queries: (report) => [
                ...InvoiceReportColumns(report).reimbursementExTax.queries(
                    report
                ),
                ...InvoiceReportColumns(report).reimbursementTax.queries(
                    report
                ),
            ],
        },
    },
    synced: {
        id: 'synced',
        label: 'Synced',
        type: 'boolean',
        width: 10,
        value: (row) => row.synced,
        queryFilters: ({ operator, value }) => {
            return queryFilters.boolean[operator]('synced', value)
        },
        queries: (report) => [
            {
                collection: 'invoices',
                fields: [['synced', 'accountingSystemInvoiceId != null']],
            },
        ],
    },
    projectStatus: {
        id: 'projectStatus',
        label: 'Project Status',
        type: 'status',
        width: 10,
        value: (row) => row.projectStatus || 'active',
        queryFilters: ({ operator, value }) => {
            return queryFilters.text[operator]('projectStatus', value)
        },
        queries: (report) => [
            {
                collection: 'invoices',
                fields: [['projectStatus', 'projects.status']],
                subQueries: [
                    {
                        collection: 'projects',
                        join: 'projectId == projects.id',
                        groupBy: ['id'],
                        fields: [['status', 'join(arr(phases.status), "")']],
                        subQueries: [
                            {
                                collection: 'phases',
                                join: 'id == phases.projectId',
                                groupBy: ['projectId'],
                                filters: ['isRootPhase != true'],
                                fields: [
                                    [
                                        'status',
                                        "('active' in arr(status)) ? 'active' : ('prospective' in arr(status)) ? 'prospective' : ('onHold' in arr(status)) ? 'onHold' : 'archived'",
                                    ],
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
})
